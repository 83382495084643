import React from "react"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"
import Submenutreneri from "../../components/submenutreneri/submenutreneri"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import "./../treneri.scss"

import ImgTreneriPolacek from "../../images/treneri/marek-polacek.jpg"

const MarekPolacek = () => (

	<div>

		<Layout>
			<Seo title="Marek Poláček" description="Marek Poláček" />

			<div className="stk-container stk-container--top-margin">

				<Row>
					<Col xs="12" xl="9">
						<div class="stk-trener__header">
							<div>
								<img class="stk-trener__image" src={ImgTreneriPolacek} alt="Marek Poláček" />
							</div>
							<div>
								<h1 className="stk-h1">Marek Poláček</h1>
								<p>Trenér - latinsko-americké tance</p>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--facebook" href="https://www.facebook.com/marek.polacek.1" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faFacebookF} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--instagram" href="https://www.instagram.com/polacek.marek66/" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faInstagram} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--phone" href="tel:+420776006953" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faPhone} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--email" href="mailto:manager@tanec-detem.cz" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faEnvelope} />
								</a>
							</div>
						</div>
					</Col>
					<Col xs="12" xl="3">
						<Submenutreneri></Submenutreneri>
					</Col>
				</Row>
			</div>
		</Layout>

	</div>

)

export default MarekPolacek